import React, {useState} from "react";
import {createUseStyles} from "react-jss";
import {Button, Drawer, IconButton, Toolbar} from "@mui/material";
import {
    AddOutlined,
    ArrowDownwardOutlined,
    ArrowUpwardOutlined,
    CenterFocusStrong,
    Close,
    ContentCopy,
    DeleteOutline,
    ImageOutlined,
} from "@mui/icons-material";
import {useScrollyTellProps, useScrollytellTranslation,} from "../Admin/scrollytellContext";
import {updateSection} from "../Admin/service";
import {ImageFocusPicker} from "../common/ImageFocusPicker";
import {getBackgroundImageString} from "../helpers/helpers";

const useStyles = createUseStyles({
    stickySectionSidebar: {
        width: "225px",
    },
    header: {
        display: "flex",
        justifyContent: "center",
        borderBottom: "2px solid #0000001F",
    },
    title: {
        fontSize: "14px",
        lineHeight: "21px",
        color: "#000000DE",
    },
    steps: {
        display: "flex",
        flexDirection: "column",
        borderBottom: "2px solid #0000001F",
    },
    step: {
        padding: "0 8px",
    },
    stepPreview: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "206px",
        height: "99px",
    },
    stepToolbar: {
        padding: "7px 0",
        display: "flex",
        justifyContent: "space-between",
    },
    stepToolbarLeft: {position: "relative"},
    stepToolbarRight: {},
    addStepButtonWrapper: {
        padding: "16px",
        display: "flex",
        justifyContent: "center",
        borderBottom: "2px solid #0000001F",
    },
    imageFocusPicker: {
        top: "0",
        width: "250px",
        left: "-5px",
    },
});

const defaultBackgroundImage = "013AjtNnCUXw";

export const StickySectionSidebar = ({
                                         isOpen,
                                         onClose,
                                         section,
                                         sectionId,
                                     }) => {
    const classes = useStyles();
    const t = useScrollytellTranslation();
    const {onMediaSelect, onChange, story} = useScrollyTellProps();
    const [focusPickerIndex, setFocusPickerIndex] = useState(null);

    const focusedImage = section?.backgroundImage[focusPickerIndex];

    const addStep = () => {
        onChange(
            updateSection(story, sectionId, {
                backgroundImage: [...section.backgroundImage, undefined],
                containers: [
                    ...section.containers,
                    {
                        startColumn: 2,
                        endColumn: 8,
                        horizontalAlign: "center",
                        stepPosition: "bottom",
                        blocks: [
                            {
                                type: "text",
                                placeholder: "Klikk her for å skrive",
                            },
                        ],
                    },
                ],
            })
        );
    };

    function dummyMediaForTest(i) {
        if (i === 0) {
            onChange(
                updateSection(story, sectionId, {
                    backgroundImage: section.backgroundImage.map((image, index) =>
                        index === i
                            ? {
                                ...image,
                                image: "013AjPGU8NCS",
                            }
                            : image
                    ),
                })
            );
        }
        if (i === 1) {
            onChange(
                updateSection(story, sectionId, {
                    backgroundImage: section.backgroundImage.map((image, index) =>
                        index === i
                            ? {
                                ...image,
                                image: "013Ajsp1c33a",
                            }
                            : image
                    ),
                })
            );
        }
        if (i === 2) {
            onChange(
                updateSection(story, sectionId, {
                    backgroundImage: section.backgroundImage.map((image, index) =>
                        index === i
                            ? {
                                ...image,
                                image: "013AjPGU8NCS",
                            }
                            : image
                    ),
                })
            );
        }
        console.warn("onMediaSelect is undefined");
    }

    const addBackgroundImageForStep = (i) => {
        if (onMediaSelect) {
            onMediaSelect("image", (dmsId) => {
                onChange(
                    updateSection(story, sectionId, {
                        backgroundImage: section.backgroundImage.map((image, index) =>
                            index === i
                                ? {
                                    ...image,
                                    image: dmsId,
                                }
                                : image
                        ),
                    })
                );
            });
        } else {
            dummyMediaForTest(i)
        }
    };
    const moveStepUp = (i) => {
        onChange(
            updateSection(story, sectionId, {
                backgroundImage: updateArrayOrder(section.backgroundImage, i, i - 1),
                containers: updateArrayOrder(section?.containers, i, i - 1),
            })
        );
    };
    const moveStepDown = (i) => {
        onChange(
            updateSection(story, sectionId, {
                backgroundImage: updateArrayOrder(section.backgroundImage, i, i + 1),
                containers: updateArrayOrder(section?.containers, i, i + 1),
            })
        );
    };
    const duplicateStep = (i) => {
        onChange(
            updateSection(story, sectionId, {
                backgroundImage: [
                    ...section.backgroundImage,
                    section.backgroundImage[i],
                ],
                containers: [...section.containers, section.containers[i]],
            })
        );
    };
    const deleteStep = (i) => {
        onChange(
            updateSection(story, sectionId, {
                backgroundImage: section.backgroundImage.filter(
                    (image, index) => index !== i
                ),
                containers: section.containers.filter(
                    (container, index) => index !== i
                ),
            })
        );
    };
    const setBackgroundFocus = (x, y) => {
        onChange(
            updateSection(story, sectionId, {
                ...section,
                backgroundImage: section.backgroundImage.map((image, index) =>
                    index === focusPickerIndex ? {...image, x, y} : image
                ),
            })
        );
    };
    const updateArrayOrder = (array, fromIndex, toIndex) => {
        const element = array[fromIndex];
        array.splice(fromIndex, 1);
        array.splice(toIndex, 0, element);
        return array;
    };

    const scrollToContainer = (containerId) => {
        const content = document.getElementById("scrollytell-content");
        if (content) {
            document
                .getElementById(`container-${sectionId}-${containerId}`)
                .scrollIntoView({behavior: "smooth", block: "center"});
        }
    };

    return (
        <>
            <Drawer
                anchor="left"
                open={isOpen}
                onClose={onClose}
                hideBackdrop={true}
                variant="persistent"
                ModalProps={{
                    keepMounted: true,
                    onBackdropClick: onClose,
                }}
            >
                <Toolbar/>
                <div className={classes.stickySectionSidebar}>
                    <div className={classes.header}>
                        <h3 className={classes.title}>
                            {t("stickySectionSidebar.title", "Sticky bakgrunnsbilder")}
                        </h3>
                        <IconButton onClick={onClose}>
                            <Close/>
                        </IconButton>
                    </div>
                    <div className={classes.steps}>
                        {section?.backgroundImage?.map((image, i) => (
                            <div className={classes.step} key={`background-image-${i}`}>
                                <h4>
                                    {`${t(
                                        "stickySectionSidebar.stepTitle",
                                        "Bakgrunnsbilde steg"
                                    )} ${i + 1}`}
                                </h4>

                                <div
                                    className={classes.stepPreview}
                                    style={{
                                        backgroundImage: getBackgroundImageString(
                                            image?.image ? image.image : defaultBackgroundImage,
                                            undefined,
                                            true,
                                            "1024x1024"
                                        ),
                                        backgroundRepeat: !image?.image ? "repeat" : "no-repeat",
                                        backgroundSize: !image?.image ? "contain" : "cover",
                                    }}
                                    onClick={() => scrollToContainer(i)}
                                >

                                    <Button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            addBackgroundImageForStep(i);
                                        }}
                                        sx={{
                                            backgroundColor: image?.image ? "#424242" : "#3F51B5",
                                            color: "white",
                                        }}
                                    >
                                        <ImageOutlined/>
                                        {image?.image
                                            ? t(
                                                "stickySectionSidebar.updateImageButtonText",
                                                "Endre bilde"
                                            )
                                            : t("stickySectionSidebar.selectImageButtonText", "Velg")}
                                    </Button>
                                </div>
                                <div className={classes.stepToolbar}>
                                    <div className={classes.stepToolbarLeft}>
                                        <IconButton
                                            sx={{borderRadius: 0, padding: "4px"}}
                                            onClick={() => {
                                                scrollToContainer(i);
                                                setFocusPickerIndex(i);
                                            }}
                                        >
                                            <CenterFocusStrong/>
                                        </IconButton>
                                        <IconButton
                                            sx={{borderRadius: 0, padding: "4px"}}
                                            onClick={() => moveStepUp(i)}
                                            disabled={i <= 0}
                                        >
                                            <ArrowUpwardOutlined/>
                                        </IconButton>
                                        <IconButton
                                            sx={{borderRadius: 0, padding: "4px"}}
                                            onClick={() => moveStepDown(i)}
                                            disabled={i >= section?.backgroundImage.length - 1}
                                        >
                                            <ArrowDownwardOutlined/>
                                        </IconButton>
                                    </div>
                                    <div className={classes.stepToolbarRight}>
                                        <IconButton
                                            sx={{borderRadius: 0, padding: "4px"}}
                                            onClick={() => duplicateStep(i)}
                                        >
                                            <ContentCopy/>
                                        </IconButton>
                                        <IconButton
                                            sx={{borderRadius: 0, padding: "4px"}}
                                            onClick={() => deleteStep(i)}
                                        >
                                            <DeleteOutline/>
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={classes.addStepButtonWrapper}>
                        <Button
                            onClick={addStep}
                            sx={{backgroundColor: "#E0E0E0", color: "#616161"}}
                        >
                            <AddOutlined/>
                            {t("stickySectionSidebar.addStepButtonTitle", "Legg til steg")}
                        </Button>
                    </div>
                </div>
            </Drawer>
            {focusPickerIndex !== null && (
                <ImageFocusPicker
                    imageUrl={focusedImage?.image}
                    x={focusedImage?.x}
                    y={focusedImage?.y}
                    onFocusChanged={(focus) => setBackgroundFocus(focus.x, focus.y)}
                    onClose={() => setFocusPickerIndex(null)}
                    className={classes.imageFocusPicker}
                />
            )}
        </>
    );
};
