import React, {useRef} from "react";
import {createUseStyles} from "react-jss";
import {ImageOutlined} from "@mui/icons-material";

import {AdminIconBar} from "../icon-bar/AdminIconBar";
import {Divider, Stack, Switch, Typography} from "@mui/material";
import {useScrollytellTranslation} from "../Admin/scrollytellContext";
import {AdminIconBarItem} from "../icon-bar/AdminIconBarItem";

const useStyles = createUseStyles({
    subMenu: {
        position: "absolute",
        left: 0,
        display: "inline-flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "8px",
        backgroundColor: "white",
        background: "#FFFFFF",
        boxShadow:
            "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)",
        borderRadius: "4px",
    },
    subMenuItem: {
        "& svg": {
            fill: "#00000099",
        },

        "&:hover svg": {
            fill: "#3F51B5",
        },
        "&.Mui-disabled": {
            cursor: "not-allowed !important",
            pointerEvents: "auto",
            "& svg": {
                fill: "#00000033",
            },
        },
    },
});

export const ImageMenu = ({block, onUpdateProperty, handleSetImage}) => {
    const t = useScrollytellTranslation();
    const imageMenuRef = useRef();
    const classes = useStyles();
    if (block.imagePath) {
        return (
            <AdminIconBar ref={imageMenuRef} className={classes.subMenu}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>
                        {t("imageBlockShowCaptionTooltip", "Vis bildetekst")}
                    </Typography>
                    <Switch
                        checked={block.caption !== undefined}
                        onChange={(e) =>
                            onUpdateProperty("caption", e.target.checked ? "" : undefined)
                        }
                        inputProps={{"aria-label": "controlled"}}
                    />
                </Stack>
                {block?.caption !== undefined && (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>
                            {t("imageBlockShowCaptionIconTooltip", "Vis bildetekst ikon")}
                        </Typography>
                        <Switch
                            checked={block.showCaptionIcon}
                            onChange={(e) =>
                                onUpdateProperty("showCaptionIcon", e.target.checked)
                            }
                            inputProps={{"aria-label": "controlled"}}
                        />
                    </Stack>
                )}
                <Divider orientation="vertical" variant="middle" flexItem/>
                <AdminIconBarItem
                    Icon={ImageOutlined}
                    onClick={() => handleSetImage()}
                    tooltip={t("imageBlockShowSetImageTooltip", "Velg bilde")}
                    classname={classes.subMenuItem}
                />
            </AdminIconBar>
        )
    } else {
        return null
    }
}
